/*
 * @Description: VueX
 */
import Vue from "vue";
import Vuex from "vuex";

import utils from "@/utils/utils.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    RequestLoading: false, // 全局loading提示
    sStyleStr: "", // 模块样式
  },
  getters: {
    getStyleStr(state) {
      return state.sStyleStr;
    },
  },
  // 同步操作
  mutations: {
    /**
     * @description:
     * @return {*}
     * @author: Hao
     */
    setStyleStr(state, str) {
      if (!str) return;
      const oStyleObject = utils.styleToObject(str); // 传递的 Style 字符串转换成对象
      if (state.sStyleStr.length) {
        let sStoreStyle = utils.styleToObject(state.sStyleStr); // Store 的 Style转换成对象

        // 检查store中是否存在相同key
        for (const key in oStyleObject) {
          if (Object.hasOwnProperty.call(oStyleObject, key)) {
            const element = oStyleObject[key];
            // 存在相同的key时直接覆盖, 不存在则新增
            if (element.toString().trim() === "") {
              delete sStoreStyle[key];
            } else {
              sStoreStyle[key] = element;
            }
          }
        }
        state.sStyleStr = utils.objectToStyle(sStoreStyle);
      } else if (state.sStyleStr.length === 0) {
        for (const key in oStyleObject) {
          state.sStyleStr += `${key}: ${oStyleObject[key]};`;
        }
      }
      // 传递参数至父
      this.commit("postStyleStr");
    },
    /**
     * @description: 新增元素
     * @return {*}
     * @author: Hao
     */
    addStyle(state, data) {
      if (!data) return;
      const { key, value } = data;
      const oStyleObject = utils.styleToObject(state.sStyleStr); // 传递的 Style 字符串转换成对象
      if (value.toString().trim() !== "") {
        oStyleObject[key] = `${value}${data.unit ? data.unit : ""}`;
      }
      state.sStyleStr = utils.objectToStyle(oStyleObject);

      // 传递参数至父
      this.commit("postStyleStr");
    },
    /**
     * @description: 删除某些元素
     * @return {*}
     * @author: Hao
     */
    deleteStyle(state, data) {
      if (!data) return;
      const oStyleObject = utils.styleToObject(state.sStyleStr); // 传递的 Style 字符串转换成对象
      if (Array.isArray(data)) {
        data.forEach((key) => {
          if (oStyleObject[key]) {
            delete oStyleObject[key];
          }
        });
      } else {
        const { key } = data;
        if (oStyleObject[key]) {
          delete oStyleObject[key];
        }
      }
      state.sStyleStr = utils.objectToStyle(oStyleObject);
      // 传递参数至父
      this.commit("postStyleStr");
    },

    /**
     * @description:
     * @param {*} state
     * @return {*}
     * @author: Hao
     */
    postStyleStr(state) {
      try {
        //判断父页面是否存在
        if (window.parent) {
          // 将修改的style传递至父页面
          console.warn("post to parent");
          window.parent.postMessage(
            {
              style: state.sStyleStr,
            },
            "*"
          );
        }
      } catch (err) {
        console.error("style传递失败", err);
      }
    },
  },
  // 异步操作
  actions: {},
});

console.warn("store ready");
