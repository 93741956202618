/*
 * @Description: 路由参数配置
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home_page.vue"),
  },
];

const router = new VueRouter({
  base: "/editor/",
  mode: "history",
  routes,
});

// 全局router前置守卫
router.beforeEach((to, form, next) => {
  next();
});
// 全局router后置守卫
router.afterEach(() => {
  // console.warn("router_after", to);
});

export default router;
