/*
 * @Description: 公共方法
 */
let oTimeObj = null; // 防抖时间对象
const funList = {
  /**
   * @description: 将样式文本转译成对象 (这里最容易出现问题)
   * @param {*} style 样式文本
   * @param {*} keyList 筛选的样式key 不传递则获取所有样式
   * @return {*}
   */
  styleToObject(style, keyList = []) {
    if (!style) return {};
    let newStyle = style
      .replace(/[\r\n]/g, "") // 去除回车等特殊字符
      .split(";")
      .filter((s) => s.length)
      .reduce((a, b) => {
        // 分离key value
        const sKey = b.split(":")[0];
        const sValue = b.slice(b.indexOf(":"), b.length).replace(":", "");
        const keyValue = [sKey, sValue];
        // cosnt keyValue = b.split(":")
        // 判断key是否为纯数组,数字则不合法
        if (
          isNaN(Number(keyValue[0])) &&
          keyValue[1] &&
          keyValue[1] !== "undefined"
        ) {
          // 获取所有数据
          if (keyList.length === 0) {
            a[keyValue[0].trim().replace(/([a-z])/gi, (s, group) => group)] =
              keyValue[1];
          } else if (keyList.indexOf(keyValue[0].trim()) !== -1) {
            a[keyValue[0].trim().replace(/([a-z])/gi, (s, group) => group)] =
              keyValue[1];
          }

          return a;
        }
      }, {});
    return newStyle || {};
  },
  /**
   * @description: 样式对象转译成字符串
   * @param {*} styleObj 样式对象
   * @return {*}
   * @author: Hao
   */
  objectToStyle(styleObj = {}) {
    let newStr = "";
    for (const key in styleObj) {
      if (Object.hasOwnProperty.call(styleObj, key)) {
        const element = styleObj[key];
        if (
          isNaN(Number(key)) &&
          element &&
          element.trim() !== "" &&
          element.trim() !== "undefined"
        ) {
          newStr += `${key}:${element};`;
        }
      }
    }
    return newStr;
  },
  /**
   * @description: 简单防抖函数 (执行函数内部存在for循环等复杂逻辑且可能出现同一时间多次执行的时候使用, 只会执行最后一次处理结果)
   * @param {*} fun 执行函数
   * @param {*} time 限制时间(秒内重复点击无效) 0 | false 立即执行
   * @param {*} data 执行函数接受的参数
   * @return {*}
   */
  delayedExecution(fun, time = 1000, data = {}) {
    if (!time) {
      fun();
    } else {
      clearTimeout(oTimeObj);
      oTimeObj = setTimeout(() => {
        fun(data);
      }, time);
    }
  },

  /**
   * @description: 复制文本
   * @param {*} text 文本内容
   * @return {*}
   * @author: Hao
   */
  async copyFun(text) {
    if (typeof text === "string") {
      try {
        let url = text;
        let oInput = document.createElement("input");
        oInput.value = url;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象;
        document.execCommand("Copy"); // 执行浏览器复制命令
        oInput.remove();
        return true;
      } catch (err) {
        console.error("复制失败", err);
        return false;
      }
    }

    /* let status = false;
		await navigator.clipboard.writeText(text).then(() => {
		  status = true;
		});
		return status; */
  },

  /**
   * @description: 分离像素单位和值
   * @param {*} str
   * @return {*}
   * @author: Hao
   */
  separateValue(str) {
    if (!str) {
      return [];
    } else {
      if (str.indexOf("%") !== -1) {
        return str.split(/([0-9]+)/gi).filter((i) => i && i.trim());
      } else {
        return str.split(/([a-z]+)/gi).map((i) => i && i.trim());
      }
    }
  },

  /**
   * @description: 去除数组中的空元素
   * @param {*} arr
   * @return {*}
   * @author: Hao
   */
  arrayTrim(arr) {
    return arr.filter((element) => {
      return element !== "" && element !== null;
    });
  },

  /**
   * @description: 解析字符串中的颜色字符
   * @param str
   * @return {*}
   */
  getColorStr(str) {
    if (!str) return [""];
    let strList = [];
    if (str.indexOf("rgb") !== -1 || str.indexOf("rgba") !== -1) {
      strList = str.replace(/(,)( )/gi, ",").split(" ");
      return strList;
    } else {
      return str.match(/#([a-z0-9]){3,6}/gi);
    }
  },

  /**
   *  RGB,Hex相互转换
   * @param colorStr
   */
  colorRGBHex(colorStr) {
    if (colorStr.indexOf("rgb") !== -1 || colorStr.indexOf("rgba") !== -1) {
      const sRgbStr = colorStr.match(/(?<=\()(.+?)(?=\))/g);
      if (sRgbStr.length) {
        const rgbArr = sRgbStr[0].split(",");
        const r = parseInt(rgbArr[0]);
        const g = parseInt(rgbArr[1]);
        const b = parseInt(rgbArr[2]);
        return (
          "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
        );
      }
    } else {
      let RGBA =
        "rgba(" +
        parseInt("0x" + colorStr.slice(1, 3)) +
        "," +
        parseInt("0x" + colorStr.slice(3, 5)) +
        "," +
        parseInt("0x" + colorStr.slice(5, 7)) +
        ")";
      console.log(RGBA);
      return RGBA;
    }
  },

  /**
   * 获取渐变色参数
   * @param str
   */
  getBackgroundGradient(str) {
    return str
      .replace(/(linear-gradient\()|\)/gi, "")
      .split(",")
      .map((item) => {
        const sValue = item.split(/[\t\r\f\n\s]*/g).join("");
        if (sValue.indexOf("to") !== -1) {
          return sValue.replace("to", "");
        } else {
          return item.trim();
        }
      });
  },
};
export default funList;
