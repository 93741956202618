/*
 * @Description: axios 请求配置
 */
import axios from "axios";
const qs = require("qs");
const serve = axios.create({
  // baseURL: process.env.NODE_ENV === 'production' ? process.env.APP_BASE_API : 'http://www.freedytech.com/api', // 获取当前应用环境 选择API地址
  baseURL: process.env.VUE_APP_API,
  timeout: 5000, // 请求超时时间
});
// http 请求拦截器
serve.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/json'; // 默认为 application/json 格式
    // formData模式配置
    if(config.reqType && config.reqType === 'formData'){
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      if(config.data){
        config.data = qs.stringify(config.data);
      }
    }
    return config;
  },
  // 请求前处理
  (err) => {
    console.error('requestErr', err);
    return Promise.reject(err);
  }
);

// http 响应拦截
serve.interceptors.response.use(
    response => {
        return response
    },
    err => {
        console.error("responseErr", err);
        return Promise.reject(err);
    }
)

export default serve;