/*
 * @Description: main.js
 */
import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./router";
import store from "./store/store";
import utils from "@/utils/utils";
import prv from "@/utils/prv";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "@/assets/css/public.css";
import "./icons";

Vue.use(VueRouter);
Vue.use(ElementUI);

// 公共方法挂载
Vue.prototype.$utils = utils;

// 获取当前运行环境路径
Vue.prototype.$baseUrl = process.env.VUE_APP_API;

// 挂载全局请求
Vue.prototype.$prv = prv;

// 是否开启运行环境提示 log
Vue.config.productionTip = false;

/* let sSempStyle = 'width:100px;height:100px;background-color:#409EFF;'
Vue.prototype.$dfStyle = sSempStyle */

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

console.warn(
  "当前环境",
  process.env.NODE_ENV,
  process.env.NODE_ENV == "development" ? "开发" : "生产"
);
console.warn("main.js ready baseURL =>", Vue.prototype.$baseUrl);
