/*
 * @Description: 请求方法列表
 */
import request from "@/axios/request";
const funList = {
  // 获取样式模板列表
  getStyleTemplateList(data = {}) {
    return request({
      url: `api/extern/?t=style_demo`,
      method: "get",
      params: data,
    });
  },

  // formData 请求
  formDataRequest(data = {}) {
    return request({
      url: `api/extern/?t=style_demo`,
      method: "get",
      reqType: "formData",
      data: data,
    });
  },
};
export default funList;
